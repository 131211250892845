import React from 'react';
import PageLayout from '../../components/page-layout';

const categories = [
  { name: 'Burgers' },
  { name: 'Wraps' },
  { name: 'Sandwiches' },
  { name: 'Fries' },
  { name: 'Biryani' },
  { name: 'Hot Beverages' },
  { name: 'Milk Shakes' },
  { name: 'Ice-Cream Shakes' },
  { name: 'Seasonal Juices' },
  { name: 'Sides' },
];

const recipesByCategory = {
  Burgers: [
    { name: 'Chicken Patty Burger', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Chicken Tikka Burger', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Zinger Burger', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Crispy Burger', realPrice: '', discountedPrice: 'Rs. 300' },
    {
      name: 'Chicken Grilled Burger',
      realPrice: '',
      discountedPrice: 'Rs. 350',
    },
    { name: 'Tower Burger', realPrice: '', discountedPrice: 'Rs. 450' },
    { name: 'Double Crunch Burger', realPrice: '', discountedPrice: 'Rs. 480' },
  ],
  Wraps: [
    {
      name: 'Small Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 450',
    },
    {
      name: 'Small Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 450',
    },
    {
      name: 'Zinger Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 360',
    },
  ],
  Sandwiches: [
    { name: 'Club Sandwich', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Tikka Sandwich', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Supreme Sandwich', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Cocktail Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Cheese Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
  ],
  Fries: [
    { name: 'Plain Fries', realPrice: '', discountedPrice: 'Rs. 200' },
    { name: 'Masala Fries', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Garlic Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Cocktail Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Chipotle Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Jalapeno Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Loaded Fries', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Cheese Blast Fries', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Pizza Fries', realPrice: '', discountedPrice: 'Rs. 370' },
  ],
  Biryani: [
    { name: 'Regular Biryani', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Extra Rice', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Extra Chicken Piece', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Shami', realPrice: '', discountedPrice: 'Rs. 70' },
  ],
  'Hot Beverages': [
    { name: 'Black Tea', realPrice: '', discountedPrice: 'Rs. 90' },
    { name: 'Green Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Milk Tea', realPrice: '', discountedPrice: 'Rs. 130' },
    { name: 'Karak Tea', realPrice: '', discountedPrice: 'Rs. 150' },
    { name: 'Small Milk Tea', realPrice: '', discountedPrice: 'Rs. 80' },
    { name: 'Small Karak Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Cappuccino', realPrice: '', discountedPrice: 'Rs. 250' },
    { name: 'Milk Coffee', realPrice: '', discountedPrice: 'Rs. 230' },
  ],
  'Milk Shakes': [
    { name: 'Apple', realPrice: '', discountedPrice: 'Rs. 180' },
    { name: 'Banana', realPrice: '', discountedPrice: 'Rs. 180' },
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 180' },
    { name: 'Oreo Chocolate', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Strawberry', realPrice: '', discountedPrice: 'Rs. 180' },
  ],
  'Ice-Cream Shakes': [
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 20' },
    { name: 'Chocolate & Caramel', realPrice: '', discountedPrice: 'Rs. 20' },
    { name: 'Vanilla', realPrice: '', discountedPrice: 'Rs. 20' },
    { name: 'Vanilla & Caramel', realPrice: '', discountedPrice: 'Rs. 20' },
  ],
  'Seasonal Juices': [
    { name: 'Falsa', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Pomegranate', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Mint Marg', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Fresh Lime', realPrice: '', discountedPrice: 'Rs. 140' },
  ],
  Sides: [
    { name: '6 Pieces', realPrice: '', discountedPrice: 'Rs. 230' },
    { name: '12 Pieces', realPrice: '', discountedPrice: 'Rs. 400' },
    { name: 'Sauce Dip (any)', realPrice: '', discountedPrice: 'Rs. 50' },
  ],
};

const Deals = () => {
  return (
    <PageLayout>
      <div className='preloader bg-main'>
        <div className='preloader-wrapper'>
          <div className='preloader-grid'>
            <div className='preloader-grid-item preloader-grid-item-1'></div>
            <div className='preloader-grid-item preloader-grid-item-2'></div>
            <div className='preloader-grid-item preloader-grid-item-3'></div>
            <div className='preloader-grid-item preloader-grid-item-4'></div>
            <div className='preloader-grid-item preloader-grid-item-5'></div>
            <div className='preloader-grid-item preloader-grid-item-6'></div>
            <div className='preloader-grid-item preloader-grid-item-7'></div>
            <div className='preloader-grid-item preloader-grid-item-8'></div>
            <div className='preloader-grid-item preloader-grid-item-9'></div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Deals;
