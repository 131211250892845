import React from 'react';
import { Link } from 'react-router-dom';

export const TopBar = () => {
  return (
    <div className='topbar'>
      <div className='container'>
        <div className='topbar-inner'>
          <div className='logo'>
            <a href='/'>
              <img src='assets/images/logo-1.png' alt='logo' />
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Link to='/deals' className='phone-container'>
              <div className='phone-container-body'>
                <span>Deals</span>
              </div>
            </Link>
            <a href='tel:+923001177360' className='phone-container'>
              <div className='phone-container-body'>
                <i className='flaticon-telephone'></i>
                <span>+92-300-1177360</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
