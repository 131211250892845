import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-overlay-1'>
      <div className='footer-upper pt-100 pb-80'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-6 order-1 order-lg-2'>
              <div className='footer-content-item text-start text-lg-center'>
                <div className='footer-logo'>
                  <a href='/'>
                    <img src='assets/images/logo-1.png' alt='logo' />
                  </a>
                </div>
                <ul className='footer-details footer-address'>
                  <li>14-C Block C1 Phase 1 Johar Town Lahore</li>
                  <li>
                    <span>Call:</span>
                    <a href='tel:+923001177360'>+92-300-1177360</a>
                  </li>
                  <li>
                    <span>Email:</span>
                    <a href='mailto:lahorebites.official@gmail.com'>
                      lahorebites.official@gmail.com
                    </a>
                  </li>
                </ul>
                <div className='footer-follow'>
                  <p>Follow Us:</p>
                  <ul className='social-list'>
                    <li>
                      <a
                        href='https://www.facebook.com/lahorebites'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='flaticon-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.twitter.com/lahorebites'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='flaticon-twitter'></i>
                      </a>
                    </li>
                    {/* <li>
                        <a href="#"><i className="flaticon-linkedin" target="_blank" rel="noreferrer"></i></a>
                      </li> */}
                    <li>
                      <a
                        href='https://www.youtube.com/channel/UCzdxbtWNq2l54IdJ-mhElyQ'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='flaticon-youtube'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.instagram.com/lahorebites'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='flaticon-instagram-1'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 order-3'>
              <div className='footer-content-list footer-content-item'>
                <div className='footer-content-title'>
                  <h3>Open Hours</h3>
                </div>
                <ul className='footer-details footer-time'>
                  <li>
                    Monday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Tuesday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Wednesday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Thursday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Friday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Saturday: <span>11:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    Sunday: <span>Closed</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
