import React from 'react';
import PageLayout from '../../components/page-layout';

const categories = [
  { name: 'Burgers' },
  { name: 'Wraps' },
  { name: 'Sandwiches' },
  { name: 'Fries' },
  { name: 'Biryani' },
  { name: 'Hot Beverages' },
  { name: 'Milk Shakes' },
  { name: 'Ice-Cream Shakes' },
  { name: 'Seasonal Juices' },
  { name: 'Sides' },
];

const recipesByCategory = {
  Burgers: [
    { name: 'Chicken Patty Burger', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Chicken Tikka Burger', realPrice: '', discountedPrice: 'Rs. 330' },
    { name: 'Zinger Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Crispy Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Chicken Grilled Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Tower Burger', realPrice: '', discountedPrice: 'Rs. 480' },
    { name: 'Double Crunch Burger', realPrice: '', discountedPrice: 'Rs. 540' },
  ],
  Wraps: [
    {
      name: 'Small Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 480',
    },
    {
      name: 'Small Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 480',
    },
    {
      name: 'Zinger Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 420',
    },
  ],
  Sandwiches: [
    { name: 'Tikka Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Supreme Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Cocktail Sandwich', realPrice: '', discountedPrice: 'Rs. 380' },
    { name: 'Cheese Sandwich', realPrice: '', discountedPrice: 'Rs. 380' },
  ],
  Fries: [
    { name: 'Plain Fries', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Masala Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Garlic Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Cocktail Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Chipotle Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Jalapeno Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Loaded Fries', realPrice: '', discountedPrice: 'Rs. 370' },
    { name: 'Cheese Blast Fries', realPrice: '', discountedPrice: 'Rs. 420' },
    { name: 'Pizza Fries', realPrice: '', discountedPrice: 'Rs. 430' },
  ],
  Biryani: [
    { name: 'Regular Biryani', realPrice: '', discountedPrice: 'Rs. 230' },
    { name: 'Extra Rice', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Extra Chicken Piece', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Shami', realPrice: '', discountedPrice: 'Rs. 70' },
  ],
  'Hot Beverages': [
    { name: 'Black Tea', realPrice: '', discountedPrice: 'Rs. 90' },
    { name: 'Green Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Milk Tea', realPrice: '', discountedPrice: 'Rs. 130' },
    { name: 'Karak Tea', realPrice: '', discountedPrice: 'Rs. 150' },
    { name: 'Small Milk Tea', realPrice: '', discountedPrice: 'Rs. 80' },
    { name: 'Small Karak Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Cappuccino', realPrice: '', discountedPrice: 'Rs. 250' },
    { name: 'Milk Coffee', realPrice: '', discountedPrice: 'Rs. 250' },
  ],
  'Milk Shakes': [
    { name: 'Apple', realPrice: '', discountedPrice: 'Rs. 200' },
    { name: 'Banana', realPrice: '', discountedPrice: 'Rs. 200' },
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Oreo Chocolate', realPrice: '', discountedPrice: 'Rs. 250' },
    { name: 'Strawberry', realPrice: '', discountedPrice: 'Rs. 200' },
  ],
  'Ice-Cream Shakes': [
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Chocolate & Caramel', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Vanilla', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Vanilla & Caramel', realPrice: '', discountedPrice: 'Rs. 280' },
  ],
  'Seasonal Juices': [
    { name: 'Falsa', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Pomegranate', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Mint Marg', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Fresh Lime', realPrice: '', discountedPrice: 'Rs. 140' },
  ],
  Sides: [
    { name: '6 Pieces', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: '12 Pieces', realPrice: '', discountedPrice: 'Rs. 430' },
    { name: 'Sauce Dip (any)', realPrice: '', discountedPrice: 'Rs. 60' },
  ],
};

const Home = () => {
  return (
    <PageLayout>
      <div className='preloader bg-main'>
        <div className='preloader-wrapper'>
          <div className='preloader-grid'>
            <div className='preloader-grid-item preloader-grid-item-1'></div>
            <div className='preloader-grid-item preloader-grid-item-2'></div>
            <div className='preloader-grid-item preloader-grid-item-3'></div>
            <div className='preloader-grid-item preloader-grid-item-4'></div>
            <div className='preloader-grid-item preloader-grid-item-5'></div>
            <div className='preloader-grid-item preloader-grid-item-6'></div>
            <div className='preloader-grid-item preloader-grid-item-7'></div>
            <div className='preloader-grid-item preloader-grid-item-8'></div>
            <div className='preloader-grid-item preloader-grid-item-9'></div>
          </div>
        </div>
      </div>
      {/* welcome-section */}
      <section className='welcome-section pb-70'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-sm-12 col-md-5 col-lg-5 pb-30'>
              <div className='section-title section-title-left text-center text-md-start m-0'>
                <h2>Fast Food and Biryani Specialist</h2>
                <small>Welcome to Lahore Bites</small>
                <p>
                  We are passionate about serving up the best fast food in town!
                  Our menu is packed with delicious options, including
                  mouth-watering burgers, sandwiches, and biryani that will have
                  your taste buds dancing with delight.
                </p>
                <p>
                  At our restaurant, we believe that every customer deserves the
                  very best service and food quality, which is why we take great
                  care in preparing each and every dish with the utmost
                  attention to detail. From the freshest ingredients to the
                  perfect seasoning, we aim to exceed your expectations with
                  every bite.
                </p>
                <p>
                  Whether you're looking for a quick bite on-the-go or a
                  sit-down meal with friends and family, our restaurant is the
                  perfect choice. So why wait? Come and experience our delicious
                  food for yourself today!
                </p>
              </div>
            </div>
            <div className='col-sm-12 col-md-7 col-lg-7'>
              <div className='about-image-grid'>
                <div className='about-image-grid-item'>
                  <div className='about-image-grid-inner mb-30'>
                    <img
                      src='assets/images/welcome-image-4.png'
                      alt='welcome'
                    />
                  </div>
                  <div className='about-image-grid-inner mb-30'>
                    <img
                      src='assets/images/welcome-image-5.png'
                      alt='welcome'
                    />
                  </div>
                </div>
                <div className='about-image-grid-item'>
                  <div className='about-image-grid-inner mb-30'>
                    <img
                      src='assets/images/welcome-image-6.png'
                      alt='welcome'
                    />
                  </div>
                  <div className='about-image-grid-inner mb-30'>
                    <img
                      src='assets/images/welcome-image-7.png'
                      alt='welcome'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* .end welcome-section */}
      {/* menu-section */}
      <section className='menu-section menu-section-bg pb-70'>
        <div className='container position-relative'>
          <div className='section-title'>
            <small>Menu</small>
            <h2>Just Choose What You Love</h2>
          </div>
          <div className='menu-main-carousel-area'>
            {categories.map((category) => (
              <div className='menu-main-details-for'>
                <h3>{category.name}</h3>
                <div className='menu-details-carousel'>
                  {recipesByCategory[category.name].map((recipe) => (
                    <div className='menu-details-carousel-item'>
                      {/* <div className='menu-details-carousel-image'>
                          <img
                            // src='assets/images/burger-slider-1.png'
                            src='assets/images/W-Crispy-Wrap.png'
                            alt='menu'
                          />
                        </div> */}
                      <div>{recipe.name}</div>
                      <div className='menu-price'>{recipe.discountedPrice}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <p className='prices-last-modified'>
            * Prices were updated on 25 February 2023
          </p>
        </div>
      </section>
      {/* .end menu-section */}
      {/* step-section */}
      {/* <section className="step-section p-tb-100 bg-black">
        <div className="container">
          <div className="section-title">
            <h2 className="color-white">3 Easy Steps To Enjoy</h2>
          </div>
          <div className="steps-box">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="steps-item">
                  <h3>1. Choose</h3>
                  <p>
                    Choose Your Best Combos From The Thousands Of Exciting Items.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="steps-item">
                  <h3>2. Pay</h3>
                  <p>
                    Pay Through Online Easily. We Use The Most Safe way In
                    Payment.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-0">
                <div className="steps-item">
                  <h3>3. Enjoy</h3>
                  <p>
                    Enjoy Your Meal From The Core Of Heart & Feel The Taste
                    Inside.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className='service-section pt-100 pb-70 bg-main bg-overlay-transparent'>
        <div className='container'>
          <div className='section-title'>
            <h2 className='color-white'>We Offer 3 Kinds Of Services</h2>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-4 pb-30'>
              <div className='service-item'>
                <div className='service-image'>
                  <img src='assets/images/service-1.jpg' alt='service' />
                </div>
                <div className='service-content'>
                  <h3>1. Dine In</h3>
                  <p>
                    Choose Your Best Combos From The Thousands Of Exciting
                    Items.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4 pb-30'>
              <div className='service-item'>
                <div className='service-image'>
                  <img src='assets/images/service-2.jpg' alt='service' />
                </div>
                <div className='service-content'>
                  <h3>2. Take Away</h3>
                  <p>
                    Choose Your Best Combos From The Thousands Of Exciting
                    Items.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4 pb-30 offset-md-3 offset-lg-0'>
              <div className='service-item'>
                <div className='service-image'>
                  <img src='assets/images/service-3.jpg' alt='service' />
                </div>
                <div className='service-content'>
                  <h3>3. Home Delivery</h3>
                  <p>
                    Choose Your Best Combos From The Thousands Of Exciting
                    Items.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* .end step-section */}
      {/* combo-section */}
      {/* <section className='combo-section bg-black pt-100 pb-70'>
        <div className='container'>
          <div className='section-title'>
            <small>Combos</small>
            <h2 className='color-white'>Our Best Combos To Choose</h2>
          </div>
          <div className='row'>
            <div
              className='col-sm-12 col-md-6 col-lg-6 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.1s'
            >
              <div className='combo-box'>
                <div className='combo-box-image'>
                  <img src='assets/images/combo-1.jpg' alt='combo' />
                </div>
                <div className='combo-box-content'>
                  <h3>Combo 1</h3>
                  <h4>
                    <span>2 Burgers</span> With French Fries
                  </h4>
                  <a href='shop-details.html' className='btn'>
                    Order Now
                    <i className='flaticon-shopping-cart-black-shape'></i>
                  </a>
                </div>
                <div className='combo-offer-shape'>
                  <div className='combo-shape-inner'>
                    <small>Only At</small>
                    <p>$10.91</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-md-6 col-lg-6 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <div className='combo-box'>
                <div className='combo-box-image'>
                  <img src='assets/images/combo-2.jpg' alt='combo' />
                </div>
                <div className='combo-box-content'>
                  <h3>Combo 2</h3>
                  <h4>
                    <span>3 Pizza</span> In 1 Combo Pack
                  </h4>
                  <a href='shop-details.html' className='btn'>
                    Order Now
                    <i className='flaticon-shopping-cart-black-shape'></i>
                  </a>
                </div>
                <div className='combo-offer-shape'>
                  <div className='combo-shape-inner'>
                    <small>Only At</small>
                    <p>$30.91</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-md-6 col-lg-6 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.5s'
            >
              <div className='combo-box'>
                <div className='combo-box-image'>
                  <img src='assets/images/combo-3.jpg' alt='combo' />
                </div>
                <div className='combo-box-content'>
                  <h3>Combo 3</h3>
                  <h4>
                    <span>3 Burgers</span> With Cold Drinks
                  </h4>
                  <a href='shop-details.html' className='btn'>
                    Order Now
                    <i className='flaticon-shopping-cart-black-shape'></i>
                  </a>
                </div>
                <div className='combo-offer-shape'>
                  <div className='combo-shape-inner'>
                    <small>Only At</small>
                    <p>$12.45</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-md-6 col-lg-6 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.7s'
            >
              <div className='combo-box'>
                <div className='combo-box-image'>
                  <img src='assets/images/combo-4.jpg' alt='combo' />
                </div>
                <div className='combo-box-content'>
                  <h3>Combo 4</h3>
                  <h4>
                    <span>3 Burgers</span> With Drinks & Salad
                  </h4>
                  <a href='shop-details.html' className='btn'>
                    Order Now
                    <i className='flaticon-shopping-cart-black-shape'></i>
                  </a>
                </div>
                <div className='combo-offer-shape'>
                  <div className='combo-shape-inner'>
                    <small>Only At</small>
                    <p>$18.75</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* .end combo-section */}
      {/* download-section */}
      {/* <section className='download-section building-shape pt-100 pb-70 bg-overlay-2'>
        <div className='container position-relative'>
          <div className='row align-items-center'>
            <div className='col-sm-12 col-lg-7 pb-30'>
              <div className='download-image-grid'>
                <div
                  className='download-image-mobile wow animate__slideInLeft'
                  data-wow-duration='1.3s'
                  data-wow-delay='0.1s'
                >
                  <div className='download-image-mobile-item'>
                    <img src='assets/images/mobile-shape-1.png' alt='mobile' />
                  </div>
                  <div className='download-image-mobile-item'>
                    <img src='assets/images/mobile-shape-2.png' alt='mobile' />
                  </div>
                </div>
                <div
                  className='download-image-rider wow animate__slideInLeft'
                  data-wow-duration='2s'
                  data-wow-delay='0.4s'
                >
                  <img src='assets/images/rider.png' alt='rider' />
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-lg-5 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <div className='about-content section-title text-center text-lg-end m-0'>
                <h2 className='color-white'>
                  Download Our App & Order Online To Get Free Delivery
                </h2>
                <p>
                  We are the country's no.1 Fast food retailer with 15+ years of
                  reputation. Country's best burger and pizza are delivered by
                  us. We gain the satisfaction of our customers with our
                  delicate service and extreme high food quality.
                </p>
                <ul className='button-list justify-content-center justify-content-lg-end'>
                  <li>
                    <a href='#' className='btn'>
                      Download From
                      <i className='flaticon-playstore'></i>
                    </a>
                  </li>
                  <li>
                    <a href='#' className='btn'>
                      Download From
                      <i className='icofont-brand-appstore'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className='testimonial-section p-tb-100 bg-black bg-overlay-1'>
        <div className='container'>
          <div className='section-title'>
            <small>Review</small>
            <h2 className='color-white'>Clients Review About Our Food</h2>
          </div>
          <div className='testimonial-carousel owl-carousel owl-theme'>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-1.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Angela Carter</h3>
                    <h4 className='carousel-designation'>CEO of Rockings</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-2.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>John Karahan</h3>
                    <h4 className='carousel-designation'>Food Bloger</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-3.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Anthony Artan</h3>
                    <h4 className='carousel-designation'>Restaurant Owner</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-1.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Angela Carter</h3>
                    <h4 className='carousel-designation'>CEO of Rockings</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-2.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>John Karahan</h3>
                    <h4 className='carousel-designation'>Food Bloger</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-3.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Anthony Artan</h3>
                    <h4 className='carousel-designation'>Restaurant Owner</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-1.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Angela Carter</h3>
                    <h4 className='carousel-designation'>CEO of Rockings</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-2.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>John Karahan</h3>
                    <h4 className='carousel-designation'>Food Bloger</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='testimonial-carousel-item bg-main'>
                <p className='carousel-para'>
                  The Taste is still in my mouth and I can feel the depth of the
                  taste of the every ingr edients used in the food. I really
                  Love Fafo.
                </p>
                <div className='carousel-info-grid'>
                  <div className='carousel-thumb'>
                    <img src='assets/images/client-3.jpg' alt='client' />
                  </div>
                  <div className='carousel-info text-end'>
                    <div className='review-star'>
                      <ul className='justify-content-end'>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                        <li className='full-star'>
                          <i className='flaticon-star-1'></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className='carousel-name'>Anthony Artan</h3>
                    <h4 className='carousel-designation'>Restaurant Owner</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <div className='video-section p-tb-100'>
        <div className='container'>
          <div className='video-button'>
            <a
              href='https://www.youtube.com/watch?v=h_YmYLYi65k'
              id='video-popup'
            >
              <i className='flaticon-play-button'></i>
            </a>
          </div>
        </div>
      </div> */}
      {/* <section className='blog-section p-tb-100 bg-overlay-2'>
        <div className='container'>
          <div className='section-title'>
            <small>Blog</small>
            <h2 className='color-white'>Read Our Latest Food News</h2>
          </div>
          <div className='row'>
            <div
              className='col-sm-12 col-md-6 col-lg-4 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.1s'
            >
              <div className='blog-card blog-card-white'>
                <div className='blog-card-thumb'>
                  <a href='blog-details.html'>
                    <img src='assets/images/blog-1.jpg' alt='blog' />
                  </a>
                </div>
                <div className='blog-card-content'>
                  <ul className='blog-entry'>
                    <li>
                      <i className='flaticon-calendar-1'></i>20 Oct 2020
                    </li>
                    <li>
                      <i className='flaticon-man-user'></i>By Admin
                    </li>
                  </ul>
                  <h3>
                    <a href='blog-details.html'>
                      Why Burger Made So Tasty In Recent Days
                    </a>
                  </h3>
                  <p>This Mexican Style Burger is pumped out with flavor</p>
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-md-6 col-lg-4 pb-30 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <div className='blog-card blog-card-white'>
                <div className='blog-card-thumb'>
                  <a href='blog-details.html'>
                    <img src='assets/images/blog-2.jpg' alt='blog' />
                  </a>
                </div>
                <div className='blog-card-content'>
                  <ul className='blog-entry'>
                    <li>
                      <i className='flaticon-calendar-1'></i>15 Oct 2020
                    </li>
                    <li>
                      <i className='flaticon-man-user'></i>By Admin
                    </li>
                  </ul>
                  <h3>
                    <a href='blog-details.html'>
                      What Is More Convenient? Dine In Or Take Away?
                    </a>
                  </h3>
                  <p>This Mexican Style Burger is pumped out with flavor</p>
                </div>
              </div>
            </div>
            <div
              className='col-sm-12 col-md-6 col-lg-4 pb-30 offset-md-3 offset-lg-0 wow animate__slideInUp'
              data-wow-duration='1s'
              data-wow-delay='0.5s'
            >
              <div className='blog-card blog-card-white'>
                <div className='blog-card-thumb'>
                  <a href='blog-details.html'>
                    <img src='assets/images/blog-1.jpg' alt='blog' />
                  </a>
                </div>
                <div className='blog-card-content'>
                  <ul className='blog-entry'>
                    <li>
                      <i className='flaticon-calendar-1'></i>12 Oct 2020
                    </li>
                    <li>
                      <i className='flaticon-man-user'></i>By Admin
                    </li>
                  </ul>
                  <h3>
                    <a href='blog-details.html'>
                      What Is The Most Hived Pizza Among The Teen Agers?
                    </a>
                  </h3>
                  <p>This Mexican Style Burger is pumped out with flavor</p>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'>
            <a href='blogs-grid-without-sidebar.html' className='btn'>
              Explore Blog <i className='flaticon-right-arrow-2'></i>
            </a>
          </div>
        </div>
      </section> */}
      {/* <section className='subscribe-section mural-bg pt-100 pb-70 bg-main'>
        <div className='container'>
          <div className='subscribe-grid'>
            <div className='subscribe-item'>
              <div className='section-title text-center text-lg-start m-0'>
                <h2 className='color-white'>Subscribe Our Newsletter</h2>
                <p>
                  Don't miss any upcoming event & Get your coupon via email.
                </p>
              </div>
            </div>
            <div className='subscribe-item'>
              <div className='subscribe-form'>
                <form
                  className='newsletter-form newsletter-form-white'
                  data-bs-toggle='validator'
                >
                  <div className='form-group'>
                    <div className='input-group'>
                      <input
                        type='email'
                        id='emails'
                        className='form-control'
                        placeholder='Enter Email Address*'
                        name='EMAIL'
                        required
                        autoComplete='off'
                      />
                    </div>
                    <button className='btn btn-yellow' type='submit'>
                      Subscribe{' '}
                      <i className='flaticon-right-arrow-sketch-1'></i>
                    </button>
                  </div>
                  <div
                    id='validator-newsletter'
                    className='form-result color-white'
                  ></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </PageLayout>
  );
};

export default Home;
