import React from 'react'
import TopBar from "../top-bar";
import Footer from "../footer";

const PageLayout = ({ children }) => {
  return (
    <>
      <TopBar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default PageLayout