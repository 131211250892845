import React from 'react';
import PageLayout from '../../components/page-layout';

const PageNotFound = () => {
  return (
    <PageLayout>
      <div className='header-bg header-bg-page'>
        <div className='header-padding position-relative'>
          <div className='header-page-shape'>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-2.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-3.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            {/* <div className="header-page-shape-item">
              <img src="assets/images/header-shape-4.png" alt="shape" />
            </div> */}
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            {/* <div className="header-page-shape-item">
              <img src="assets/images/header-shape-3.png" alt="shape" />
            </div> */}
          </div>
          <div className='container'>
            <div className='header-page-content'>
              <h1>404 Error Page</h1>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='index.html'>Home</a>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    404 Error Page
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className='error-section p-tb-100 bg-black'>
        <div className='container'>
          <div className='error-page-content'>
            <div className='error-page-image text-center'>
              <img src='assets/images/404.png' alt='404' className='mx-auto' />
            </div>
            <div className='error-page-text'>
              <h2 className='color-white'>Error 404: Page not found</h2>
              <p>The page you were looking for could not be found.</p>
              <a
                href='/'
                className='btn btn-red btn-icon btn-shadow btn-shadow-red'
              >
                Go To Homepage <i className='flaticon-right-chevron'></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className='subscribe-section mural-bg pt-100 pb-70 bg-main'>
        <div className='container'>
          <div className='subscribe-grid'>
            <div className='subscribe-item'>
              <div className='section-title text-center text-lg-start m-0'>
                <h2 className='color-white'>Subscribe Our Newsletter</h2>
                <p>
                  Don't miss any upcoming event & Get your coupon via email.
                </p>
              </div>
            </div>
            <div className='subscribe-item'>
              <div className='subscribe-form'>
                <form
                  className='newsletter-form newsletter-form-white'
                  data-bs-toggle='validator'
                >
                  <div className='form-group'>
                    <div className='input-group'>
                      <input
                        type='email'
                        id='emails'
                        className='form-control'
                        placeholder='Enter Email Address*'
                        name='EMAIL'
                        required
                        autocomplete='off'
                      />
                    </div>
                    <button className='btn btn-yellow' type='submit'>
                      Subscribe <i className='flaticon-right-arrow-sketch-1'></i>
                    </button>
                  </div>
                  <div
                    id='validator-newsletter'
                    className='form-result color-white'
                  ></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default PageNotFound;
