import React from 'react';
import PageLayout from '../../components/page-layout';
import MenuCategory from '../../components/MenuCategory';

const Recipe = ({ name, realPrice, discountedPrice }) => {
  return (
    <div className='receipe-item receipe-item-black pb-30 receipe-grid-item'>
      <div className='receipe-item-inner'>
        <div className='receipe-image'>
          <img src='assets/images/burger-receipe-1.png' alt='receipe' />
        </div>
        <div className='receipe-content'>
          <div className='receipe-info'>
            <h3>
              <a href='shop-details.html'>{name}</a>
            </h3>
            <h4>
              {discountedPrice}
              {realPrice.length > 0 && <del>{realPrice}</del>}
            </h4>
          </div>
          {/* <div className='receipe-cart'>
            <a href='#'>
              <i className='flaticon-supermarket-basket'></i>
              <i className='flaticon-supermarket-basket'></i>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const categories = [
  { name: 'Burgers' },
  { name: 'Wraps' },
  { name: 'Sandwiches' },
  { name: 'Pastas' },
  { name: 'Fries' },
  { name: 'Biryani' },
  { name: 'Hot Beverages' },
  { name: 'Beverages' },
  { name: 'Milk Shakes' },
  { name: 'Ice-Cream Shakes' },
  { name: 'Seasonal Juices' },
  { name: 'Sides' },
];

const recipesByCategory = {
  Burgers: [
    { name: 'Chicken Patty Burger', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Chicken Tikka Burger', realPrice: '', discountedPrice: 'Rs. 330' },
    { name: 'Zinger Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Crispy Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Chicken Grilled Burger', realPrice: '', discountedPrice: 'Rs. 350' },
    { name: 'Tower Burger', realPrice: '', discountedPrice: 'Rs. 480' },
    { name: 'Double Crunch Burger', realPrice: '', discountedPrice: 'Rs. 540' },
  ],
  Wraps: [
    {
      name: 'Small Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Grilled Burrito Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 480',
    },
    {
      name: 'Small Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 280',
    },
    {
      name: 'Large Chicken Crispy Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 480',
    },
    {
      name: 'Zinger Wrap',
      realPrice: '',
      discountedPrice: 'Rs. 420',
    },
  ],
  Sandwiches: [
    { name: 'Tikka Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Supreme Sandwich', realPrice: '', discountedPrice: 'Rs. 340' },
    { name: 'Cocktail Sandwich', realPrice: '', discountedPrice: 'Rs. 380' },
    { name: 'Cheese Sandwich', realPrice: '', discountedPrice: 'Rs. 380' },
  ],
  Fries: [
    { name: 'Plain Fries', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Masala Fries', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: 'Garlic Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Cocktail Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Chipotle Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Jalapeno Fries', realPrice: '', discountedPrice: 'Rs. 270' },
    { name: 'Loaded Fries', realPrice: '', discountedPrice: 'Rs. 370' },
    { name: 'Cheese Blast Fries', realPrice: '', discountedPrice: 'Rs. 420' },
    { name: 'Pizza Fries', realPrice: '', discountedPrice: 'Rs. 430' },
  ],
  Biryani: [
    { name: 'Regular Biryani', realPrice: '', discountedPrice: 'Rs. 230' },
    { name: 'Extra Rice', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Extra Chicken Piece', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Shami', realPrice: '', discountedPrice: 'Rs. 70' },
  ],
  'Hot Beverages': [
    { name: 'Black Tea', realPrice: '', discountedPrice: 'Rs. 90' },
    { name: 'Green Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Milk Tea', realPrice: '', discountedPrice: 'Rs. 130' },
    { name: 'Karak Tea', realPrice: '', discountedPrice: 'Rs. 150' },
    { name: 'Small Milk Tea', realPrice: '', discountedPrice: 'Rs. 80' },
    { name: 'Small Karak Tea', realPrice: '', discountedPrice: 'Rs. 100' },
    { name: 'Cappuccino', realPrice: '', discountedPrice: 'Rs. 250' },
    { name: 'Milk Coffee', realPrice: '', discountedPrice: 'Rs. 250' },
  ],
  'Milk Shakes': [
    { name: 'Apple', realPrice: '', discountedPrice: 'Rs. 200' },
    { name: 'Banana', realPrice: '', discountedPrice: 'Rs. 200' },
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 220' },
    { name: 'Oreo Chocolate', realPrice: '', discountedPrice: 'Rs. 250' },
    { name: 'Strawberry', realPrice: '', discountedPrice: 'Rs. 200' },
  ],
  'Ice-Cream Shakes': [
    { name: 'Chocolate', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Chocolate & Caramel', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Vanilla', realPrice: '', discountedPrice: 'Rs. 280' },
    { name: 'Vanilla & Caramel', realPrice: '', discountedPrice: 'Rs. 280' },
  ],
  'Seasonal Juices': [
    { name: 'Falsa', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Pomegranate', realPrice: '', discountedPrice: 'Rs. 300' },
    { name: 'Mint Marg', realPrice: '', discountedPrice: 'Rs. 160' },
    { name: 'Fresh Lime', realPrice: '', discountedPrice: 'Rs. 140' },
  ],
  Sides: [
    { name: '6 Pieces', realPrice: '', discountedPrice: 'Rs. 240' },
    { name: '12 Pieces', realPrice: '', discountedPrice: 'Rs. 430' },
    { name: 'Sauce Dip (any)', realPrice: '', discountedPrice: 'Rs. 60' },
  ],
};

const Menu = () => {
  return (
    <PageLayout>
      <div className='header-bg header-bg-page'>
        <div className='header-padding position-relative'>
          <div className='header-page-shape'>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-2.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-3.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-4.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-1.png' alt='shape' />
            </div>
            <div className='header-page-shape-item'>
              <img src='assets/images/header-shape-3.png' alt='shape' />
            </div>
          </div>
          <div className='container'>
            <div className='header-page-content'>
              <h1>Our Menu</h1>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='index.html'>Home</a>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    Our Menu
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className='menu-section bg-black p-tb-100'>
        <div className='container position-relative'>
          <div className='section-title'>
            <small>Menu</small>
            <h2 className='color-white'>Just Choose What You Love</h2>
          </div>
          <div className='menu-main-carousel-area'>
            <div className='menu-main-thumb-nav'>
              {categories.map((category, i) => (
                <MenuCategory key={`category-${i}`} name={category.name} />
              ))}
            </div>
            <div className='menu-main-details-for'>
              {categories.map((category) => (
                <div className='menu-main-details-item'>
                  <div className='receipe-grid receipe-grid-three'>
                    {recipesByCategory[category.name].map((recipe) => (
                      <Recipe {...recipe} />
                    ))}
                  </div>
                  <div className='text-center'>
                    <a href='pro' className='btn load-more-btn'>
                      <span className='load-more-text'>Load More</span>
                      <span className='load-more-icon'>
                        <i className='icofont-refresh'></i>
                      </span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className='step-section p-tb-100 bg-black'>
        <div className='container'>
          <div className='section-title'>
            <h2 className='color-white'>3 Easy Steps To Enjoy</h2>
          </div>
          <div className='steps-box'>
            <div className='row'>
              <div className='col-sm-12 col-md-6 col-lg-4'>
                <div className='steps-item'>
                  <h3>1. Choose</h3>
                  <p>Choose Your Favorite Food From The Our Menu</p>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4'>
                <div className='steps-item'>
                  <h3>2. Place Order</h3>
                  <p>
                    Call To Our Hotline And We'll Deliver It To Your Doorstep
                  </p>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-0'>
                <div className='steps-item'>
                  <h3>3. Enjoy</h3>
                  <p>
                    Enjoy Your Meal From The Core Of Heart & Feel The Taste
                    Inside.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default Menu;
