import React from 'react';

const MenuCategory = ({ name }) => {
  return (
    <div className='menu-main-thumb-item menu-main-thumb-black'>
      <div className='menu-main-thumb-inner'>
        <img src='assets/images/menu-1.png' alt='menu' />
        <p>{name}</p>
      </div>
    </div>
  );
};

export default MenuCategory;